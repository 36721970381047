import React, { useEffect, Fragment, useState, useMemo } from 'react';
import { Mesh } from 'three';
import { MeshBVH, acceleratedRaycast } from 'three-mesh-bvh';
import { connect } from 'react-redux';
import { values, cloneDeep } from 'lodash';
import {
  createMeshsArray,
  LoadingProgress,
  Renderer,
  Splitter,
  createPanorameMesh,
  ConditionNavigation,
} from '@web-3d-tool/shared-ui';
import { shellActions, rendererActions } from '@web-3d-tool/redux-logic';
import { strings } from '@web-3d-tool/localization';
import { TOOGLE_MENU360 } from '@web-3d-tool/shared-logic/src/constants/tools.constants';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { menuTypes360 } from '@web-3d-tool/shared-logic/src/constants/menuTypes360.constants';

import {
  utils,
  logger,
  logToTimber,
  logToTimberBI,
  eventBus,
  globalEventsKeys,
  cacheManager,
  cacheKeys,
  settingsManager,
  requestsManager,
  featureAvaliability,
  biMethods,
  appSettingsService,
} from '@web-3d-tool/shared-logic';
import classNames from 'classnames';
import ToolsContainer from './components/ToolsContainer';
import AppPreset from './components/Preset';
import styles from './App.module.css';

const { imperativeThreeObjectsReady, cameraStoppedMoving, twoFingersDoubleTap } = rendererActions;
const { appLoaded, setWindowSize, presetLoaded } = shellActions;

const { dualView } = strings;
const translationsForSplitter = {
  lower: dualView ? dualView?.lower : 'Lower',
  upper: dualView ? dualView?.upper : 'Uppwer',
};

const extendMeshAndGeometry = (model) => {
  Mesh.prototype.raycast = acceleratedRaycast;
  return Object.values(model.objects).map((geometry) => {
    // MeshBVH: Only BufferGeometries are supported.
    if (geometry.type === 'BufferGeometry') {
      geometry.boundsTree = new MeshBVH(geometry);
    }
    return { geometry };
  });
};

const prepareMetadata = ({ currentMetadata, materialsObject, model, isModelCompareActivated }) => {
  if (isModelCompareActivated) {
    cacheManager.set(cacheKeys.CAMERA_MODEL_COMPARE, null);
  }
  const metadata = cloneDeep(currentMetadata);
  [...values(metadata.lower_jaw), ...values(metadata.upper_jaw)].forEach((object) => {
    if (object.material) {
      const { uuid } = object.material;
      object.material = materialsObject[uuid];
    }
  });
  const geometries = extendMeshAndGeometry(model);
  const meshes = createMeshsArray(model, metadata);

  return { geometries, meshes };
};

const renderStage = ({
  model,
  compareModel,
  metadata: currentMetadata,
  compareMetadata,
  materialsObject,
  compareMaterialsObject,
  stage,
  appProps,
  onMount,
  onMountCompare,
  resetCameraRotationOnUpdate,
  menuType360,
  panorama,
  isOnLanding,
  imageFrameDimentions,
  isModelCompareEnabled,
  isModelCompareActive,
  isModelSynced,
  isSplittedViewWithSidePluginActive,
  isModelCompareInDifferentMode,
  explorers,
  currentExplorer,
}) => {
  const numberOfItems = stage.reduce((count, current) => count + current.length, 0);

  return (
    <Splitter
      propsMatrix={stage}
      key={numberOfItems} //we want to cause rerendering in case of spliting renderer
      isOnLanding={isOnLanding}
      isModelCompareActive={isModelCompareActive}
      imageFrameDimentions={imageFrameDimentions}
      isSplittedViewWithSidePluginActive={isSplittedViewWithSidePluginActive}
      translationsForSplitter={translationsForSplitter}
      renderComp={(props, rowIndex, rendererInRowIndex) => {
        const isModelCompareActivated = isModelCompareEnabled && compareMetadata && compareModel;
        const modelToPrepare = isModelCompareActivated
          ? (() => {
              return rendererInRowIndex === 0
                ? {
                    model: compareModel,
                    currentMetadata: compareMetadata,
                  }
                : {
                    model,
                    currentMetadata,
                  };
            })()
          : {
              model,
              currentMetadata,
            };

        const { geometries, meshes } = prepareMetadata({
          currentMetadata: modelToPrepare.currentMetadata,
          materialsObject: isModelCompareActivated ? compareMaterialsObject : materialsObject,
          model: modelToPrepare.model,
          isModelCompareActivated,
        });

        const panoramaMesh = createPanorameMesh(panorama, meshes);

        const rendererProps = {
          meshes: meshes,
          panoramaMesh,
          isModelCompareActive,
          isModelSynced,
        };
        if (
          (isSplittedViewWithSidePluginActive && rowIndex === 0) ||
          (rowIndex === 0 && rendererInRowIndex === 0) ||
          isModelCompareActivated
        ) {
          // we are sending the three objects via redux only for the first renderer
          rendererProps.getThreeJSObjects = (objects) =>
            appProps.imperativeThreeObjectsReady({ objects, rendererInRowIndex });
          rendererProps.onTap2Fingers = (controls) =>
            eventBus.subscribeToEvent(globalEventsKeys.TAP_2_FINGERS, controls);
          rendererProps.onDoubletap2Fingers = (controls) => appProps.twoFingersDoubleTap(controls);
        }

        rendererProps.onCameraMove = ({ target: { camera } }) =>
          eventBus.raiseEvent(globalEventsKeys.CAMERA_CHANGED, { camera });
        rendererProps.onCameraStopMoving = ({ target: { camera } }) =>
          appProps.cameraStoppedMoving({
            camera,
            arrayIndex: rowIndex,
            inArrayIndex: rendererInRowIndex,
          });

        return (
          <Renderer
            {...props}
            {...rendererProps}
            geometries={geometries}
            onMount={isModelCompareActivated ? onMountCompare : onMount}
            id={`renderer-${rowIndex}-${rendererInRowIndex}`}
            resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
            menuType360={menuType360}
            rendererInRowIndex={rendererInRowIndex}
            numberOfItems={numberOfItems}
            isSplittedViewWithSidePluginActive={isSplittedViewWithSidePluginActive}
            imageFrameDimentions={imageFrameDimentions}
            isModelCompareInDifferentMode={isModelCompareInDifferentMode}
          />
        );
      }}
    />
  );
};

const PluginsViews = ({ pluginsViews }) =>
  values(pluginsViews).map((view, index) => <Fragment key={index}>{view}</Fragment>);

const handleResizeWindow = (e, setWindowSize) => {
  const { innerWidth: width, innerHeight: height } = e.target;
  eventBus.raiseEvent(globalEventsKeys.RESIZING, { width, height });
  setWindowSize({ width, height });
};

const appVersion = utils.getAppVersion();

const logAboutAppLoaded = (timeToLoad) => {
  const dataToLog = {
    module: 'app',
    version: appVersion,
    modelUrl: requestsManager.getModelUrl(),
    niriUrl: requestsManager.getNiriFilePath(),
    bffUrl: settingsManager.getConfigValue(configValues.serverEndpoint),
    timeToLoad,
  };

  logger
    .info('App rendered')
    .data(dataToLog)
    .to(['analytics', 'host'])
    .end();

  //Should probably remove this in 21A, as not to add to console blindness:
  console.log('Time till rendering is shown on screen: ', timeToLoad);

  logToTimber({
    timberData: {
      action: 'loaded',
      module: 'web-viewer',
      type: 'page',
      actor: 'System',
      value: timeToLoad,
    },
  });

  logToTimberBI(
    biMethods.specialBiLog({
      specialEventType: 'WebViewerLoaded',
      userId: settingsManager.getConfigValue(configValues.userId) || 'NO_USER_ID_PARAM',
      companyId: settingsManager.getConfigValue(configValues.companyId) || 'NO_COMPANY_ID_PARAM',
      orderId: settingsManager.getConfigValue(configValues.orderId),
      rxId: settingsManager.getConfigValue(configValues.rxGuid),
      appName: 'web-3d-tool',
      appVersion,
      desktopSessionId: settingsManager.getConfigValue(configValues.SessionId) || null,
    })
  );
};

const App = (props) => {
  const {
    pluginsZones,
    isThreejsObjectsReady,
    isPresetLoaded,
    appLoaded,
    presetLoaded,
    model: modelId,
    compareModel: compareModelId,
    metadata,
    compareMetadata,
    pluginsViews,
    stage,
    panorama,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    isModelCompareActive,
    plugin360Parameters,
    isModelSynced,
    isModelCompareInDifferentMode,
  } = props;
  const [model, setModel] = useState(null);
  const [compareModel, setCompareModel] = useState(null);
  const [materialsObject, setMaterialsObject] = useState(null);
  const [compareMaterialsObject, setCompareMaterialsObject] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isMountedCompare, setIsMountedCompare] = useState(false);
  const [isModelCompareUnloaded, setIsModelCompareUnloaded] = useState(false);
  const onMount = () => setIsMounted(true);
  const onMountCompare = () => setIsMountedCompare(true);
  const isLoading = !(isMounted && isThreejsObjectsReady && isPresetLoaded) || modelIsLoading;
  const isModelCompareEnabled = isModelCompareActive && featureAvaliability.isSideBySideCompareEnabled();
  const { currentExplorer, menuType, explorers } = plugin360Parameters || {};
  const isOnLanding = useMemo(() => menuType === menuTypes360.CIRCULAR, [menuType]);
  const isCompareModelId = compareModelId && compareModelId.id;
  const [isSplittedViewWithSidePluginActive, setIsSplittedViewWithSidePluginActive] = useState(
    cacheManager.get(cacheKeys.SPLITTED_VIEW_WITH_SIDE_PLUGIN_ACTIVE)
  );
  const [imageFrameDimentions, setImageFrameDimentions] = useState({ width: 0, drawerWidth: 0 });

  useEffect(() => {
    if (isMounted) {
      const timeToLoad = logger.timeEnd();
      logAboutAppLoaded(timeToLoad);
      const visibilityObject = cacheManager.get(cacheKeys.VISIBILITY_OBJECT);
      const model = cacheManager.get(cacheKeys.MODEL);
      eventBus.raiseEvent(globalEventsKeys.MODEL_LOADED, { model, visibilityObject });
    }
  }, [isMounted, modelId]);

  useEffect(() => {
    const compareModel = cacheManager.get(cacheKeys.COMPARE_MODEL);
    const visibilityObject = cacheManager.get(cacheKeys.COMPARE_VISIBILITY_OBJECT);

    if (isMountedCompare && compareModel && visibilityObject && !isModelCompareUnloaded) {
      eventBus.raiseEvent(globalEventsKeys.MODEL_LOADED, {
        model: compareModel,
        visibilityObject,
        isModelCompareLoaded: true,
      });
    }

    const compareModelUnloadEvent = eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
      setIsModelCompareUnloaded(true);
      compareModelUnloadEvent.unsubscribe();
    });

    if (isModelCompareUnloaded) {
      eventBus.raiseEvent(globalEventsKeys.AFTER_MODEL_UNLOAD_COMPLETTE);
      setIsModelCompareUnloaded(false);
    }
  }, [isMountedCompare, isCompareModelId, isModelCompareUnloaded]);

  useEffect(() => {
    const loadApp = async () => {
      await appSettingsService.requestSingle({ selector: 'environmentParametersSettings' });
      appLoaded();
      logger.time('AT.APP_LOADED');
      window.addEventListener('resize', (e) => handleResizeWindow(e, props.setWindowSize));

      eventBus.subscribeToEvent(globalEventsKeys.SPLITTED_VIEW_WITH_SIDE_PLUGIN_CHANGED, ({ isActive }) => {
        setIsSplittedViewWithSidePluginActive(isActive);
        setImageFrameDimentions(cacheManager.get(cacheKeys.IMAGE_FRAME_DIMENTIONS_CHANGE));
      });

      eventBus.subscribeToEvent(globalEventsKeys.MODEL_UNLOADED, () => {
        setIsMountedCompare(false);
      });

      return () => {
        window.removeEventListener('resize', (e) => handleResizeWindow(e, props.setWindowSize));
      };
    };
    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const modelFromCache = cacheManager.get(cacheKeys.MODEL);
    const compareModelFromCache = cacheManager.get(cacheKeys.COMPARE_MODEL);
    setModel(modelFromCache);
    setCompareModel(compareModelFromCache);
    setMaterialsObject(cacheManager.get(cacheKeys.MATERIALS));
    setCompareMaterialsObject(cacheManager.get(cacheKeys.COMPARE_MATERIALS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, compareModelId]);

  return (
    <div className={classNames(styles.app, currentExplorer ? styles.fadeInContainer : '')}>
      {model &&
        !modelIsLoading &&
        isPresetLoaded &&
        materialsObject &&
        renderStage({
          model,
          compareModel,
          metadata,
          compareMetadata,
          panorama,
          materialsObject,
          compareMaterialsObject,
          stage,
          appProps: props,
          onMount,
          onMountCompare,
          resetCameraRotationOnUpdate,
          menuType360: menuType,
          isOnLanding,
          imageFrameDimentions,
          isModelCompareEnabled,
          isModelCompareActive,
          isModelSynced,
          isSplittedViewWithSidePluginActive,
          isModelCompareInDifferentMode,
          explorers,
          currentExplorer,
        })}

      {explorers && <ConditionNavigation explorers={explorers} activeExplorer={currentExplorer}></ConditionNavigation>}
      {pluginsZones && <AppPreset zones={pluginsZones} presetLoaded={presetLoaded} />}
      {pluginsViews && <PluginsViews pluginsViews={pluginsViews} />}

      <ToolsContainer style={{ position: 'absolute' }} />

      {isLoading && (
        <div className={styles.progress}>
          <LoadingProgress width={80} height={80} />
        </div>
      )}
      <div className={styles.appVersion} id="appVersion">
        [ {appVersion} ]
      </div>
    </div>
  );
};

const mapStateToProps = ({ renderer, shell, plugins }) => {
  const {
    model,
    compareModel,
    metadata,
    compareMetadata,
    stage,
    isThreejsObjectsReady,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    panorama,
    isModelCompareActive,
    isModelSynced,
    isModelCompareInDifferentMode,
  } = renderer;
  const { pluginsZones, pluginsViews, isPresetLoaded } = shell;
  const plugin360Parameters = plugins[TOOGLE_MENU360.id].pluginParameters;

  return {
    model,
    compareModel,
    pluginsZones,
    metadata,
    compareMetadata,
    pluginsViews,
    stage,
    panorama,
    isThreejsObjectsReady,
    isPresetLoaded,
    resetCameraRotationOnUpdate,
    modelIsLoading,
    isModelCompareActive,
    plugin360Parameters,
    isModelSynced,
    isModelCompareInDifferentMode,
  };
};

export default connect(
  mapStateToProps,
  {
    appLoaded,
    presetLoaded,
    imperativeThreeObjectsReady,
    cameraStoppedMoving,
    twoFingersDoubleTap,
    setWindowSize,
  }
)(App);
