import React from 'react';
import { utils, cacheManager, cacheKeys, scannerVersionManager } from '@web-3d-tool/shared-logic';
import styles from './Splitter.module.css';
import styles360 from './Splitter360.module.css';

const Splitter = (props) => {
  const {
    renderComp,
    propsMatrix,
    isModelCompareActive,
    imageFrameDimentions = { width: window.innerWidth / 4 + 32, drawerWidth: 32 },
    isSplittedViewWithSidePluginActive,
    translationsForSplitter,
    isOnLanding,
  } = props;
  const { getIs360HubEnabled, calc360HubCircularMenuRadius, isScannerHostEnv } = utils;
  const is360 = getIs360HubEnabled();
  const landingPageRadius = calc360HubCircularMenuRadius();
  const margin = '0 auto';

  const getWidth = (propsArr) => {
    if (isSplittedViewWithSidePluginActive && is360) {
      return `calc(( 100vw - ${imageFrameDimentions?.width}px + ${imageFrameDimentions?.drawerWidth}px ) / ${propsArr.length}  )`;
    }
    if (isOnLanding) {
      return `${2 * landingPageRadius}px`;
    }
    return '100%';
  };

  const getHeight = () => (isOnLanding ? `${2 * landingPageRadius}px` : '100%');

  const getModelCompareDate = (orderKey) => {
    const dateObj = cacheManager.get(cacheKeys.SELECTED_ORDER_DATE) || {};
    return utils.formatDateAndTime(dateObj[orderKey]).formattedDate;
  };

  const getCurrentModelAlias = ({ split, propsArrLength, isModelCompareActive }) => {
    if (propsArrLength === 1) {
      return getModelCompareDate('current');
    }

    if (propsArrLength === 2) {
      if (isModelCompareActive) {
        return getModelCompareDate(split === 0 ? 'selected' : 'current');
      } else {
        return split === 0 ? translationsForSplitter.lower : translationsForSplitter.upper;
      }
    }
  };

  const isCurrentModelAliasAllowed = (propsArrLength) => {
    const isScannerHost = isScannerHostEnv();
    const scannerVersion = scannerVersionManager.getScannerVersion();
    const is22BScannerVersion = isScannerHost && scannerVersion === scannerVersionManager.scannerAssetsVersions.v22B;
    const hasCurrentDateData = getModelCompareDate('current') !== 'Invalid date';
    const shoudlDisplayDate = !(propsArrLength === 2 && !isModelCompareActive);
    return (hasCurrentDateData || !shoudlDisplayDate) && is360 && !(is22BScannerVersion && propsArrLength === 1);
  };

  return (
    <div className={styles.container}>
      {propsMatrix.map((propsArr, i) => (
        <div
          key={i}
          className={is360 ? styles360.splitterRow : styles.splitterRow}
          style={{ height: `${100 / propsMatrix.length}%` }}
        >
          {propsArr.map((componentProps, j) => (
            <div
              style={{
                width: getWidth(propsArr),
                height: getHeight(),
                ...(isOnLanding ? { margin: margin } : {}),
              }}
              key={j}
            >
              {renderComp(componentProps, i, j)}
              {isCurrentModelAliasAllowed(propsArr.length) && (
                <div className={styles360.splitterRowSign}>
                  {(() => {
                    return getCurrentModelAlias({ split: j, propsArrLength: propsArr.length, isModelCompareActive });
                  })()}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Splitter;
